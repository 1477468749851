import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './PagamentoPix.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Confetti from 'react-confetti';

import pixIcon from './pix.svg';
import paypalIcon from './paypal.svg';
import mercadoIcon from './mercado.svg';
import pagseguroIcon from './PAGS.svg';

const Pagamento = () => {
    const [loading, setLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('Mensal');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [paymentLink, setPaymentLink] = useState('');
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false); // Controla a exibição da mensagem

    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const userId = localStorage.getItem('userId');
    const email = userDetails ? userDetails.email : 'email@example.com';
    const nome = userDetails ? `${userDetails.nome} ${userDetails.sobrenome}` : 'Usuário';

    const plans = {
        Mensal: 9.90,
        Semestral: 56.90,
        Anual: 106.90
    };

    const gerarPagamentoPix = async () => {
        setLoading(true);

        if (!email || !userId) {
            toast.error('Informações do usuário não encontradas no localStorage');
            setLoading(false);
            return;
        }

        try {
            const response = await fetch('https://apipagamentoclube.squareweb.app/api/createpayment', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    value: plans[selectedPlan],
                    email: email,
                    user_id: userId,
                    external_reference: userId
                })
            });

            const data = await response.json();

            if (response.ok) {
                setPaymentLink(data.paymentLink);
                setShowModal(true); // Exibe o modal com o iframe
            } else {
                throw new Error(data.error || 'Erro ao gerar o link de pagamento via Pix');
            }
        } catch (err) {
            console.error('Erro:', err);
            toast.error(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSubscribe = () => {
        if (!selectedPaymentMethod) {
            toast.error('Por favor, selecione um método de pagamento.');
            return;
        }

        switch (selectedPaymentMethod) {
            case 'Pix':
                gerarPagamentoPix();
                break;
            case 'Paypal':
                toast.error('Pagamento via PayPal está desativado no momento.');
                break;
            case 'MercadoPago':
                toast.error('Pagamento via MercadoPago está desativado no momento.');
                break;
            case 'PagSeguro':
                toast.error('Pagamento via PagSeguro está desativado no momento.');
                break;
            default:
                toast.error('Método de pagamento inválido.');
        }
    };

    const handlePaymentMethodSelect = (method) => {
        setSelectedPaymentMethod(selectedPaymentMethod === method ? '' : method);
    };

    // Função para fechar o modal ao clicar fora dele
    const handleModalClick = (e) => {
        if (e.target.className === 'payment-modal-overlay') {
            setShowModal(false);
        }
    };

    // Verifica se há pagamento recente na tabela `pagamentos`
    useEffect(() => {
        if (showModal) {
            const interval = setInterval(async () => {
                try {
                    const response = await fetch('https://apipagamentoclube.squareweb.app/api/checkpayment', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ user_id: userId })
                    });

                    const data = await response.json();
                    if (data.success && data.recentPayment) {
                        setShowModal(false);  // Fecha o modal
                        setPaymentSuccess(true); // Exibe a animação de confetes
                        setShowSuccessMessage(true); // Exibe a mensagem de sucesso
                        setTimeout(() => {
                            setPaymentSuccess(false);
                            setShowSuccessMessage(false); // Esconde a mensagem após 10 segundos
                            window.location.href = '/dashboard'; // Redireciona para o dashboard
                        }, 20000); // Exibe a animação e a mensagem por 10 segundos
                    } else {
                        console.log('Pagamento ainda não confirmado.');
                    }
                } catch (error) {
                    console.error('Erro ao verificar pagamento:', error);
                }
            }, 3000); // Verifica o pagamento a cada 3 segundos

            return () => clearInterval(interval); // Limpa o intervalo quando o componente desmonta
        }
    }, [showModal, userId]);

    return (
        <div className="payment-page">
            <div className="content">
                <div className="content-left">
                    <div className="info-section">
                        <div className="info-text">
                            <div className="titulo-grande">Estamos quase lá!</div>
                            <div className="texto-normal">
                                Olá {nome}, mais alguns passos <br /> e você poderá aproveitar todos os<br /> benefícios do Clube do Dinheiro.
                            </div>
                        </div>
                        <div className="info-details">
                            <div className="titulo-medio">Informação da conta</div>
                            <div className="texto-normal">
                                {nome} <Link to="/logout" className="logout-link">Não é você?</Link>
                            </div>
                            <div className="texto-normal">{email}</div>
                        </div>
                    </div>

                    <section className="plans-section">
                        <h2>Escolha o Seu Plano</h2>
                        <div className="plans">
                            <div 
                                className={`plan ${selectedPlan === 'Mensal' ? 'selected' : ''}`}
                                onClick={() => setSelectedPlan('Mensal')}
                            >
                                <h3>Mensal</h3>
                                <p>R$ {plans.Mensal.toFixed(2)}</p>
                                <p>Pagamento mensal</p>
                            </div>
                            <div 
                                className={`plan ${selectedPlan === 'Semestral' ? 'selected' : ''}`}
                                onClick={() => setSelectedPlan('Semestral')}
                            >
                                <h3>Semestral</h3>
                                <p>R$ {plans.Semestral.toFixed(2)}</p>
                                <p>Economize 5%</p>
                            </div>
                            <div 
                                className={`plan ${selectedPlan === 'Anual' ? 'selected' : ''}`}
                                onClick={() => setSelectedPlan('Anual')}
                            >
                                <h3>Anual</h3>
                                <p>R$ {plans.Anual.toFixed(2)}</p>
                                <p>Economize 10%</p>
                            </div>
                        </div>
                    </section>

                    <section className="payment-method-section">
                        <h2>Formas de Pagamento</h2>
                        <div className="payment-options">
                            <button 
                                className={`payment-option ${selectedPaymentMethod === 'Pix' ? 'selected' : ''}`} 
                                onClick={() => handlePaymentMethodSelect('Pix')} 
                                disabled={loading}
                            >
                                <img src={pixIcon} alt="Pix Icon" />
                                <p>Pix (QR Code)</p>
                            </button>
                            <button 
                                className={`payment-option ${selectedPaymentMethod === 'Paypal' ? 'selected' : ''}`} 
                                onClick={() => handlePaymentMethodSelect('Paypal')} 
                                disabled
                            >
                                <img src={paypalIcon} alt="Paypal Icon" />
                                <p>Paypal (Indisponível)</p>
                            </button>
                            <button 
                                className={`payment-option ${selectedPaymentMethod === 'MercadoPago' ? 'selected' : ''}`} 
                                onClick={() => handlePaymentMethodSelect('MercadoPago')} 
                                disabled
                            >
                                <img src={mercadoIcon} alt="MercadoPago Icon" />
                                <p>MercadoPago (Indisponível)</p>
                            </button>
                            <button 
                                className={`payment-option ${selectedPaymentMethod === 'PagSeguro' ? 'selected' : ''}`} 
                                onClick={() => handlePaymentMethodSelect('PagSeguro')} 
                                disabled
                            >
                                <img src={pagseguroIcon} alt="PagSeguro Icon" />
                                <p>PagSeguro (Indisponível)</p>
                            </button>
                        </div>
                    </section>
                </div>

                <div className="content-right">
                    <section className="summary-section">
                        <h2>Resumo</h2>
                        <p>Plano Selecionado: <strong>{selectedPlan}</strong></p>
                        <p>Total: R$ {plans[selectedPlan].toFixed(2)}</p>
                        <button className="subscribe-button" onClick={handleSubscribe} disabled={loading}>Subscrever</button>
                        <p className="disclaimer">Os planos não são renovados automaticamente.</p>
                    </section>
                </div>
            </div>

            <ToastContainer />

            {/* Modal para exibir o link de pagamento */}
            {showModal && (
                <div className="payment-modal-overlay" onClick={handleModalClick}>
                    <div className="payment-modal">
                        <h2>Finalizar Pagamento</h2>
                        <iframe src={paymentLink} width="100%" height="400px" title="Payment Link" />
                    </div>
                </div>
            )}

            {/* Animação de Confetti após o pagamento */}
            {paymentSuccess && <Confetti numberOfPieces={800} />}

            {/* Mensagem de sucesso no meio da tela */}
            {showSuccessMessage && (
                <div className="success-message">
                    <h2>Pagamento realizado com sucesso!</h2>
                    <p>Obrigado por se tornar um assinante premium do Clube do Dinheiro.</p>
                </div>
            )}
        </div>
    );
};

export default Pagamento;
