import React, { useEffect } from 'react';
import Confetti from 'react-confetti'; // Certifique-se de que você tenha este pacote instalado
import './Compracerta.css'; // Importa o arquivo CSS
import { useNavigate } from 'react-router-dom'; // Importa o useNavigate para redirecionamento

const Compracerta = () => {
  const navigate = useNavigate(); // Inicializa o hook useNavigate

  useEffect(() => {
    // Qualquer lógica adicional que você queira adicionar ao carregar o componente
  }, []);

  // Função para redirecionar para a página de login
  const handleLoginRedirect = () => {
    navigate('/login'); // Mude para a rota correta da sua página de login
  };

  return (
    <div className="compracerta-page"> {/* Classe atualizada */}
      <Confetti />
      <div className="confetti-message">
        <h1>Pagamento realizado com sucesso!</h1>
        <p>Obrigado por se tornar um assinante premium do Clube do Dinheiro.</p>
        <button onClick={handleLoginRedirect} className="login-button">
          Fazer Login
        </button>
      </div>
    </div>
  );
};

export default Compracerta;
