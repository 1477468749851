// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* grafico lançamento */
.card-grafico .card-content {
    height: 100%;
    padding: 0; /* Remover padding para ocupar todo o espaço */
}

.card-grafico canvas {
    width: 100% !important;
    height: 100% !important;
    max-height: 100%; /* Garantir que o gráfico não ultrapasse o tamanho do card */
}

`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/css/LancamentosAnoCard.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,YAAY;IACZ,UAAU,EAAE,8CAA8C;AAC9D;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB,EAAE,4DAA4D;AAClF","sourcesContent":["/* grafico lançamento */\n.card-grafico .card-content {\n    height: 100%;\n    padding: 0; /* Remover padding para ocupar todo o espaço */\n}\n\n.card-grafico canvas {\n    width: 100% !important;\n    height: 100% !important;\n    max-height: 100%; /* Garantir que o gráfico não ultrapasse o tamanho do card */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
