// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Adiciona estilos específicos para a página de redefinição de senha */

.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  background-color: var(--bs-light);
}

.reset-password-box {
  background-color: var(--bs-light);
  padding: 2em;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.password-input-wrapper {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--secondary-color);
}

.login-button {
  width: 100%;
  padding: 0.5em;
  background-color: var(--primary-color);
  color: var(--light-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.login-button:hover {
  background-color: var(--primary-monochromatic1);
}
`, "",{"version":3,"sources":["webpack://./src/pages/ResetPassword/ChangePassword.css"],"names":[],"mappings":"AAAA,uEAAuE;;AAEvE;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,iCAAiC;AACnC;;AAEA;EACE,iCAAiC;EACjC,YAAY;EACZ,kBAAkB;EAClB,uCAAuC;EACvC,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,2BAA2B;EAC3B,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,cAAc;EACd,sCAAsC;EACtC,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,+CAA+C;AACjD","sourcesContent":["/* Adiciona estilos específicos para a página de redefinição de senha */\n\n.reset-password-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 50vh;\n  background-color: var(--bs-light);\n}\n\n.reset-password-box {\n  background-color: var(--bs-light);\n  padding: 2em;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);\n  text-align: center;\n  width: 100%;\n  max-width: 400px;\n}\n\n.password-input-wrapper {\n  position: relative;\n}\n\n.password-toggle-icon {\n  position: absolute;\n  top: 50%;\n  right: 10px;\n  transform: translateY(-50%);\n  cursor: pointer;\n  color: var(--secondary-color);\n}\n\n.login-button {\n  width: 100%;\n  padding: 0.5em;\n  background-color: var(--primary-color);\n  color: var(--light-color);\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  font-size: 1em;\n}\n\n.login-button:hover {\n  background-color: var(--primary-monochromatic1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
