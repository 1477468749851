import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importa o useNavigate para redirecionamento
import './CompraErrada.css'; // Importa o arquivo CSS

const CompraErrada = () => {
  const navigate = useNavigate(); // Inicializa o hook useNavigate

  // Função para redirecionar para a página inicial ou outra
  const handleRedirect = () => {
    navigate('/login'); // Mude para a rota desejada
  };

  return (
    <div className="compraerrada-container">
      <div className="message1">
        <h1 className="titulo1">Oops! Algo deu errado...</h1>
        <p className="menasgemp">Parece que houve um problema com sua compra.</p>
        <button onClick={handleRedirect} className="redirect-button">
          Voltar para a página inicial
        </button>
      </div>
    </div>
  );
};

export default CompraErrada;
