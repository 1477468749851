// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 2em;
}

.password-input-wrapper {
    position: relative;
  }
  
  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--secondary-color);
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Login/login.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,2BAA2B;IAC3B,eAAe;IACf,6BAA6B;EAC/B","sourcesContent":["header {\n    background-color: var(--primary-color);\n    color: var(--light-color);\n    padding: 2em;\n}\n\n.password-input-wrapper {\n    position: relative;\n  }\n  \n  .password-toggle-icon {\n    position: absolute;\n    top: 50%;\n    right: 10px;\n    transform: translateY(-50%);\n    cursor: pointer;\n    color: var(--secondary-color);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
