// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Compracerta.css */
.compracerta-page {
    height: 100vh; /* Ocupa 100% da altura da viewport */
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    color: #333;
    background: #ededed; /* Cor de fundo suave */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ocultar barras de rolagem */
  }
  
  .confetti-message {
    position: relative;
    text-align: center;
    z-index: 10; /* Certifique-se de que o texto fique acima do confete */
  }
  
  h1 {
    font-size: 2.5em; /* Tamanho do texto do título */
    margin: 0; /* Remove a margem padrão */
  }
  
  p {
    font-size: 1.2em; /* Tamanho do texto do parágrafo */
    margin-top: 10px; /* Espaçamento acima do parágrafo */
  }
  
  .login-button {
    margin-top: 20px; /* Espaçamento acima do botão */
    padding: 10px 20px; /* Estilo do botão */
    font-size: 1em; /* Tamanho da fonte do botão */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/pagamento/Compracerta.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,aAAa,EAAE,qCAAqC;IACpD,SAAS;IACT,UAAU;IACV,8BAA8B;IAC9B,WAAW;IACX,mBAAmB,EAAE,uBAAuB;IAC5C,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB,EAAE,8BAA8B;EAClD;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;IAClB,WAAW,EAAE,wDAAwD;EACvE;;EAEA;IACE,gBAAgB,EAAE,+BAA+B;IACjD,SAAS,EAAE,2BAA2B;EACxC;;EAEA;IACE,gBAAgB,EAAE,kCAAkC;IACpD,gBAAgB,EAAE,mCAAmC;EACvD;;EAEA;IACE,gBAAgB,EAAE,+BAA+B;IACjD,kBAAkB,EAAE,oBAAoB;IACxC,cAAc,EAAE,8BAA8B;EAChD","sourcesContent":["/* Compracerta.css */\n.compracerta-page {\n    height: 100vh; /* Ocupa 100% da altura da viewport */\n    margin: 0;\n    padding: 0;\n    font-family: Arial, sans-serif;\n    color: #333;\n    background: #ededed; /* Cor de fundo suave */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    overflow: hidden; /* Ocultar barras de rolagem */\n  }\n  \n  .confetti-message {\n    position: relative;\n    text-align: center;\n    z-index: 10; /* Certifique-se de que o texto fique acima do confete */\n  }\n  \n  h1 {\n    font-size: 2.5em; /* Tamanho do texto do título */\n    margin: 0; /* Remove a margem padrão */\n  }\n  \n  p {\n    font-size: 1.2em; /* Tamanho do texto do parágrafo */\n    margin-top: 10px; /* Espaçamento acima do parágrafo */\n  }\n  \n  .login-button {\n    margin-top: 20px; /* Espaçamento acima do botão */\n    padding: 10px 20px; /* Estilo do botão */\n    font-size: 1em; /* Tamanho da fonte do botão */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
