// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CSS específico para a página CompraErrada */

.compraerrada-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ocupa 100% da altura da tela */
    background: linear-gradient(to right, #ffafbd, #ffc3a0); /* Gradiente de fundo */
    text-align: center; /* Centraliza o texto */
}

.message1 {
    background: white; /* Fundo branco para contraste */
    padding: 20px;
    border-radius: 10px; /* Cantos arredondados */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Sombra */
}

.titulo1 {
    color: #e63946; /* Cor do título */
    margin-bottom: 10px; /* Espaço abaixo do título */
}

.menasgemp {
    color: #555; /* Cor do texto */
    margin-bottom: 20px; /* Espaço abaixo do parágrafo */
}

.redirect-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #e63946; /* Cor do botão */
    color: white; /* Cor do texto do botão */
    cursor: pointer; /* Cursor de ponteiro */
    transition: background-color 0.3s; /* Efeito de transição */
}

.redirect-button:hover {
    background-color: #d62839; /* Cor ao passar o mouse */
}
`, "",{"version":3,"sources":["webpack://./src/pages/pagamento/CompraErrada.css"],"names":[],"mappings":"AAAA,8CAA8C;;AAE9C;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,iCAAiC;IAChD,uDAAuD,EAAE,uBAAuB;IAChF,kBAAkB,EAAE,uBAAuB;AAC/C;;AAEA;IACI,iBAAiB,EAAE,gCAAgC;IACnD,aAAa;IACb,mBAAmB,EAAE,wBAAwB;IAC7C,yCAAyC,EAAE,WAAW;AAC1D;;AAEA;IACI,cAAc,EAAE,kBAAkB;IAClC,mBAAmB,EAAE,4BAA4B;AACrD;;AAEA;IACI,WAAW,EAAE,iBAAiB;IAC9B,mBAAmB,EAAE,+BAA+B;AACxD;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB,EAAE,iBAAiB;IAC5C,YAAY,EAAE,0BAA0B;IACxC,eAAe,EAAE,uBAAuB;IACxC,iCAAiC,EAAE,wBAAwB;AAC/D;;AAEA;IACI,yBAAyB,EAAE,0BAA0B;AACzD","sourcesContent":["/* CSS específico para a página CompraErrada */\n\n.compraerrada-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh; /* Ocupa 100% da altura da tela */\n    background: linear-gradient(to right, #ffafbd, #ffc3a0); /* Gradiente de fundo */\n    text-align: center; /* Centraliza o texto */\n}\n\n.message1 {\n    background: white; /* Fundo branco para contraste */\n    padding: 20px;\n    border-radius: 10px; /* Cantos arredondados */\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Sombra */\n}\n\n.titulo1 {\n    color: #e63946; /* Cor do título */\n    margin-bottom: 10px; /* Espaço abaixo do título */\n}\n\n.menasgemp {\n    color: #555; /* Cor do texto */\n    margin-bottom: 20px; /* Espaço abaixo do parágrafo */\n}\n\n.redirect-button {\n    padding: 10px 20px;\n    border: none;\n    border-radius: 5px;\n    background-color: #e63946; /* Cor do botão */\n    color: white; /* Cor do texto do botão */\n    cursor: pointer; /* Cursor de ponteiro */\n    transition: background-color 0.3s; /* Efeito de transição */\n}\n\n.redirect-button:hover {\n    background-color: #d62839; /* Cor ao passar o mouse */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
