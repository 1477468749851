// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#receitasDespesasBarChartCard {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    box-sizing: border-box;
}

#receitasDespesasBarChartCard .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: var(--primary-color);
    color: #fff;
    font-weight: bold;
    border-bottom: 1px solid var(--secondary-color);
    font-size: 1.1em;
    cursor: move;
}

#receitasDespesasBarChartCard .card-content {
    flex-grow: 1; 
    display: flex;
    align-items: stretch; /* Alinhamento para esticar o conteúdo */
    justify-content: center;
    padding: 10px;
}

#receitasDespesasBarChartCard .card-content canvas {
    width: 100% !important; /* Certifique-se de que o gráfico ocupe 100% do contêiner */
    height: 100% !important; /* Certifique-se de que o gráfico ocupe 100% do contêiner */
}

#receitasDespesasBarChartCard button {
    margin-left: auto;
    padding: 5px 10px;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#receitasDespesasBarChartCard button:hover {
    background-color: var(--primary-dark);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/css/ReceitasDespesasBarChart.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;IACb,sCAAsC;IACtC,WAAW;IACX,iBAAiB;IACjB,+CAA+C;IAC/C,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,oBAAoB,EAAE,wCAAwC;IAC9D,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,sBAAsB,EAAE,2DAA2D;IACnF,uBAAuB,EAAE,2DAA2D;AACxF;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,sCAAsC;IACtC,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,qCAAqC;AACzC","sourcesContent":["#receitasDespesasBarChartCard {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    padding: 0;\n    box-sizing: border-box;\n}\n\n#receitasDespesasBarChartCard .card-header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 10px;\n    background-color: var(--primary-color);\n    color: #fff;\n    font-weight: bold;\n    border-bottom: 1px solid var(--secondary-color);\n    font-size: 1.1em;\n    cursor: move;\n}\n\n#receitasDespesasBarChartCard .card-content {\n    flex-grow: 1; \n    display: flex;\n    align-items: stretch; /* Alinhamento para esticar o conteúdo */\n    justify-content: center;\n    padding: 10px;\n}\n\n#receitasDespesasBarChartCard .card-content canvas {\n    width: 100% !important; /* Certifique-se de que o gráfico ocupe 100% do contêiner */\n    height: 100% !important; /* Certifique-se de que o gráfico ocupe 100% do contêiner */\n}\n\n#receitasDespesasBarChartCard button {\n    margin-left: auto;\n    padding: 5px 10px;\n    background-color: var(--primary-color);\n    color: #fff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n}\n\n#receitasDespesasBarChartCard button:hover {\n    background-color: var(--primary-dark);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
