import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Signup from './pages/Signup/Signup';
import Logout from './pages/Logout/Logout';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ChangePassword from './pages/ResetPassword/ChangePassword';
import Profile from './pages/Profile/Profile'; 
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedLevelRoute from './components/ProtectedLevelRoute'; // Importa o novo componente
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import FloatingButton from './components/FloatingButton/FloatingButton';
import Config from './pages/Config/Config'; 
import Admin from './pages/Admin/Admin';
import Categorias from './pages/Categorias/Categorias';
import FormasPagamento from './pages/FormaPagamento/FormasPagamento';
import Lancamentos from './pages/Lancamentos/Lancamentos';
import Metas from './pages/Metas/Metas';
import Relatorios from './pages/Relatorios/Relatorios';
import Carteiras from './pages/Carteiras/Carteiras';
import Cartoes from './pages/CartoesCredito/CartoesCredito';
import Planos from './pages/Planos/Planos';
import Compracerta from './pages/pagamento/Compracerta';
import CompraErrada from './pages/pagamento/CompraErrada';
import Pagamento from './pages/pagamento/Pagamento'

function App() {
  const [selectedDate, setSelectedDate] = useState(localStorage.getItem('selectedDate') || '');

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                <div className="page-container">
                  <Header onDateChange={handleDateChange} />
                  <FloatingButton />
                  <Dashboard selectedDate={selectedDate} />
                  <Footer />
                </div>
              </ProtectedRoute>
            } 
          />
          <Route path="/signup" element={<Signup />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password/:hash" element={<ChangePassword />} />
          <Route 
            path="/profile" 
            element={
              <ProtectedRoute>
                <div className="page-container">
                  <Header onDateChange={handleDateChange} />
                  <FloatingButton />
                  <Profile />
                  <Footer />
                </div>
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/config" 
            element={
              <ProtectedRoute>
                <ProtectedLevelRoute userId={localStorage.getItem('userId')}>
                  <div className="page-container">
                    <Header onDateChange={handleDateChange} />
                    <FloatingButton />
                    <Config />
                    <Footer />
                  </div>
                </ProtectedLevelRoute> 
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/admin" 
            element={
              <ProtectedRoute>
                <div className="page-container">
                  <Header onDateChange={handleDateChange} />
                  <FloatingButton />
                  <Admin />
                  <Footer />
                </div>
              </ProtectedRoute>
            } 
          />
          <Route 
              path="/categorias" 
              element={
                  <ProtectedRoute>
                      <div className="page-container">
                          <Header />
                          <Categorias />
                          <Footer />
                      </div>
                  </ProtectedRoute>
              } 
          />
          <Route 
              path="/formaspagamento" 
              element={
                  <ProtectedRoute>
                      <div className="page-container">
                          <Header />
                          <FormasPagamento />
                          <Footer />
                      </div>
                  </ProtectedRoute>
              } 
          />
          {/* Rotas protegidas por nível de usuário e data de cadastro */}
          <Route 
              path="/lancamentos" 
              element={
                  <ProtectedRoute>
                    <ProtectedLevelRoute userId={localStorage.getItem('userId')}>
                        <div className="page-container">
                            <Header onDateChange={handleDateChange} />
                            <Lancamentos selectedDate={selectedDate} />
                            <Footer />
                        </div>
                    </ProtectedLevelRoute>
                  </ProtectedRoute>
              } 
          />
          <Route 
              path="/metas" 
              element={
                <ProtectedRoute>
                  <ProtectedLevelRoute userId={localStorage.getItem('userId')}>
                    <div className="page-container">
                      <Header onDateChange={handleDateChange} />
                      <Metas />
                      <Footer />
                    </div>
                  </ProtectedLevelRoute>
                </ProtectedRoute>
              } 
          />
          <Route 
              path="/relatorios" 
              element={
                <ProtectedRoute>
                  <ProtectedLevelRoute userId={localStorage.getItem('userId')}>
                    <div className="page-container">
                      <Header onDateChange={handleDateChange} />
                      <Relatorios selectedDate={selectedDate} />
                      <Footer />
                    </div>
                  </ProtectedLevelRoute>
                </ProtectedRoute>
              } 
          />
          <Route 
              path="/carteiras" 
              element={
                <ProtectedRoute>
                  <ProtectedLevelRoute userId={localStorage.getItem('userId')}>
                    <div className="page-container">
                        <Header onDateChange={handleDateChange} />
                        <Carteiras />
                        <Footer />
                    </div>
                  </ProtectedLevelRoute>
                </ProtectedRoute>
              } 
          />
          <Route 
              path="/cartoes" 
              element={
                <ProtectedRoute>
                  <ProtectedLevelRoute userId={localStorage.getItem('userId')}>
                    <div className="page-container">
                        <Header onDateChange={handleDateChange} />
                        <Cartoes />
                        <Footer />
                    </div>
                  </ProtectedLevelRoute>
                </ProtectedRoute>
              } 
          />
          <Route 
              path="/Compracerta" 
              element={
                    <div className="page-container">
                        <Compracerta />
                    </div>
              } 
          />
          <Route 
              path="/CompraErrada" 
              element={
                    <div className="page-container">
                        <CompraErrada />
                    </div>
              } 
          />
          <Route 
              path="/planos" 
              element={
                    <div className="page-container">
                        <Planos />
                    </div>
              } 
          />
 
          <Route 
              path="/Pagamento" 
              element={
                <ProtectedRoute>
                    <div className="page-container">
                        <Header onDateChange={handleDateChange} />
                        <Pagamento />
                        <Footer />
                    </div>
                </ProtectedRoute>
              } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
