import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Planos.css';

const Planos = () => {
  const navigate = useNavigate();

  // Função para redirecionar ao clicar no botão de assinar o plano grátis
  const handleAssinarGratis = () => {
    navigate('/login');
  };

  // Função para redirecionar ou realizar ações para o plano premium
  const handleAssinarPremium = () => {
    // Aqui você poderia redirecionar para uma página de pagamento ou outra ação
    window.location.href = 'https://clubedodinheiro.lemonsqueezy.com/buy/ac467d23-9c05-47b3-8981-8599f12d3a95';
  };

  const planos = [
    {
      id: 1,
      nome: 'Plano Básico',
      preco: 'R$ 0 / mês',
      descricao: 'Ideal para quem está começando.',
      beneficios: [
        { text: 'Acesso limitado às funcionalidades', enabled: true },
        { text: 'Gestão de Despesas Básica', enabled: true },
        { text: 'Acompanhamento de Gastos', enabled: true },
        { text: 'Dashboard dos Lançamentos', enabled: true },
        { text: '1 Carteira', enabled: true },
        { text: '1 Cartão', enabled: true },
        { text: '3 Lançamentos Mensais', enabled: true },
        { text: 'Acesso às Metas', enabled: false },
        { text: 'Acesso aos Relatórios', enabled: false },
        { text: 'Notificações', enabled: false },
        { text: 'Assistente AI Serena', enabled: false },
        { text: '7 dias com acesso total ao sistema', enabled: true },
      ],
      tipo: 'basico',
    },
    {
      id: 2,
      nome: 'Plano Premium',
      preco: 'R$ 9,90 / mês',
      descricao: 'Para quem deseja aproveitar tudo!',
      beneficios: [
        { text: 'Acesso a todas as funcionalidades', enabled: true },
        { text: 'Gestão de Despesas Avançada', enabled: true },
        { text: 'Acompanhamento de Gastos', enabled: true },
        { text: 'Dashboard dos Lançamentos', enabled: true },
        { text: 'Carteiras Ilimitadas', enabled: true },
        { text: 'Cartões Ilimitados', enabled: true },
        { text: 'Lançamentos Ilimitados', enabled: true },
        { text: 'Acesso às Metas', enabled: true },
        { text: 'Acesso aos Relatórios', enabled: true },
        { text: 'Novas Categorias Ilimitadas', enabled: true },
        { text: 'Novas Formas de Pagamento Ilimitadas', enabled: true },
        { text: 'Permite notificações', enabled: true },
        { text: 'Lembrete de lançamentos', enabled: true },
        { text: 'Assistente AI Serena', enabled: true },
      ],
      tipo: 'premium',
    },
  ];

  return (
    <div className="planos-container">
      <h2>Escolha o plano ideal para você</h2>
      <div className="planos-lista">
        {planos.map((plano) => (
          <div className={`plano-card ${plano.tipo}`} key={plano.id}>
            <h3>{plano.nome}</h3>
            <p className="plano-preco">{plano.preco}</p>
            <p className={`plano-descricao ${plano.tipo}`}>{plano.descricao}</p>
            <ul className={`plano-beneficios ${plano.tipo}`}>
              {plano.beneficios.map((beneficio, index) => (
                <li
                  key={index}
                  className={beneficio.enabled ? 'enabled' : 'disabled'}
                >
                  {beneficio.text}
                </li>
              ))}
            </ul>
            <button
              className={`btn-assinar ${plano.tipo}`}
              onClick={
                plano.preco === 'R$ 0 / mês'
                  ? handleAssinarGratis
                  : handleAssinarPremium
              }
            >
              {plano.preco === 'R$ 0 / mês' ? 'Assinar Plano Grátis' : 'Assinar Premium'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Planos;
